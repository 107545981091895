.Root {
    justify-content: center;
    vertical-align: middle;
}

.Paper {
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    max-width: 600px;
    width: 100%;
    height: 460px;
    margin: auto;
    padding-top: 4rem;
}

.Paper p {
    margin: 20px 20px;
}

.Buttons {
    margin: auto;
    padding-top: 10px;
}

.Buttons .HomeButton {
    margin: 20px;
}


.Buttons .AgainButton {
    margin: 20px;
}

.Link {
    color: white;
    text-decoration: none;
}

.WrapperProgressBar {
    background: white;
    margin: 20px auto;
    margin-top: 20px;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    width: 340px;
    height: 30px;
}

.WrapperProgressBar .ProgressBar {
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    max-width: 400px;
    width: 260px;
    margin: auto;
    padding: auto;
    flex-grow: 1;
}
.Center {
    margin-left: 18px;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
}

.Emoji {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: table-cell;
}