.Root {
    justify-content: center;
    vertical-align: middle;
}


.Paper {
    text-align: center;
    width: 90%;
    max-width: 800px;
    height: 460px;
    margin: auto;
    padding-top: 4rem;
}

.Paper p {
    margin: 40px auto;
}

.Button {
    margin-top: 50px;
}

.Link {
    color: white;
    text-decoration: none;
}

.Item {
    margin: auto 10px;
    margin-top: 20px;
    }