.Paper {
    text-align: left;
    align-items: center;
    vertical-align: middle;
    width: 350px;
    margin: 40px auto;
    padding: 1rem;
}


.InsidePaper {
    padding-top: 10px;
    height: 100%;
}

.QOrder {
    font-weight: bold;
    text-align: center
}

.QText {
    margin: 20px;
}
.Choice {
    padding: .3rem 0;
    text-align: left;
    margin: auto;
    width: 100%;
    border: solid 1px #eee;
    margin:10px auto;
}

.FormControl {
    width: 100%;
}