.Root {
    justify-content: center;
    vertical-align: middle;
    margin-top: 90px;
}

.Button {
    margin: 20px;
    margin-top: 30px;
}


.Link {
    color: white;
    text-decoration: none;
}