.Root {
    justify-content: center;
    vertical-align: middle;
}

.List {
    justify-content: center;
    vertical-align: middle;
}

.Button {
    margin: 20px;
    margin-top: 30px;
}

h2 {
    font-size: 17pt;
}

.Root .Item {
    margin: 10px;
}

.Root .Item .TextField {
    width: 250px;
    font-size: 16px;
    margin: 20px;
}

.Alert {
    color: red;
    font-size: 10pt;
    margin-top: -6px;
}

.Message {
    padding-top: 20px;
}

 .Wrong {
    color: red;
}

.Root .List .Divider {
    margin-bottom: 10px;
}

.Link {
    color: white;
    text-decoration: none;
}