.Root {
    justify-content: center;
    vertical-align: middle;
}

.Paper {
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    height: 460px;
    margin: auto;
    padding-top: 4rem;
}

.Paper p {
    margin: 40px auto;
}