.Root {
    margin-top: 100px;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
}

.Message {
    font-size: 14px;
    color: red;
}

.Paper {
    padding-top: 50px;
    height: 400px;
    /* background-color: black; */
}

.Items {
    margin: 50px auto;
}


.TextField {
    width: 150px;
    font-size: 16px;
}
