.Paper {
    margin: 60px 10px;
    padding: 20px 5px;
}

.HomeBtn {
    margin: 15px;
}

.Link {
    text-decoration: none;
}