.Root {
    justify-content: center;
    vertical-align: middle;
}

.Message {
    font-size: 12pt;
    margin: 30px auto;
}

h2 {
    font-size: 17pt;
}

.Button {
    margin-top: 30px;
}


.TextField {
    width: 250px;
    font-size: 16px;
    margin: 20px;
}

.Item {
    margin: 20px;
}

.Message {
    padding-top: 20px;
}

 .Wrong {
    color: red;
}