.Root {
    justify-content: center;
    vertical-align: middle;
    text-align: center;
}

.Button {
    margin-top: 40px;
}

.TextField {
    width: 250px;
    font-size: 16px;
}

.Item {
    margin-top: 20px;
}

.Message {
    padding-top: 20px;
}

 .Wrong {
    color: red;
}